import { getDataFromTemplate } from "@/http/api"
import http from "@/http/http"
import { Button, Input, message } from "antd"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import styled from "styled-components"


const VerifyStyleStyled = styled.div`
    padding: 50px;

    .header{
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .input{
            width: 200px;
            margin-left: 20px;
        }
    }

    .content{
        display: flex;
        column-gap: 20px;
        margin: 30px 0;
        justify-content: space-between;
    }
`

const VerifyStyle = () => {

    const [inputValue, setInputValue] = useState('');
    const [page, setPage] = useState(1); 
    const [data, setData] = useState({})
    const [count, setCount] = useState(null)

    useEffect(() => {
        fetchData()
    }, [page])

    const fetchData = async () => {
        const hide = message.loading('加载中')
        const res = await http.get(`resume/develop/listOriginResumeTemplate?page=${page}&limit=${1}`)
        const { id: resId } = await getDataFromTemplate({
            query: { resumeId: res.dataList[0].id },
          })
          setCount(res.count)
          setData({...res.dataList[0], resumeId: resId})
          hide()
    }

    const handlePress = () => {
        setPage(inputValue)
    }

    return (
        <VerifyStyleStyled>

            <div className="header">
                <Button className="btn" onClick={() => setPage(i => i + 1)}>下一页</Button>
                <Button style={{marginLeft: 10}} className="btn" onClick={() => setPage(i => i - 1)}>上一页</Button>
                <Input placeholder="输入第几页查询" className="input" onChange={(e) => setInputValue(e.target.value)} onPressEnter={handlePress}/>
                <div style={{marginLeft: 20}}>当前页数：{page}</div>
                <div style={{marginLeft: 20}}>总页数：{count}</div>
            </div>

            <div className="content">

            <div style={{ flex:1 }}>
                <div>简历来源：{data.origin}</div>
            {!isEmpty(data) && <img src={JSON.parse(data.info).origin_pic_url} style={{width:'100%'}}/>}
            </div>
            <div style={{ flex:1 }}>
                <div>ID：{data.resumeId}</div>
            <iframe
                style={{ overflow: 'auto' }}
                width="100%"
                height="1630"
                className="iframe"
                loading="lazy"
                id={data?.id}
                src={`/resume/container?id=${data.resumeId}`} />
            </div>

            </div>

        </VerifyStyleStyled>
    )
}

export default VerifyStyle