import withComponent from "@/hoc/withComponent";
import {createElement} from "react";
import {useSelector} from "@/components/resume/redux/hooks";
import getNestedProperty from "@/utils/getNestedProperty";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import getResumePath from "@/common/resume/getResumePath";
import moment from "moment";
import getPlaceholderBySlot from "@/common/config/getPlaceholder";
import { endYear } from "@/components/resume/components/StartAndEnd";
import { convertEngDate } from "@/utils/dateToeng";

export default withComponent((props) => {
    const {tagName, style, className, slot_value} = props;
    const fieldInstance = useFieldInstance();
    const moduleContentInstance = useModuleContentInstance();
    const placeholder = getPlaceholderBySlot(fieldInstance?.for_block_name, fieldInstance?.for_field_name,slot_value);
    const path = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, slot_value, moduleContentInstance?.index);
    const value = useSelector((state) => getNestedProperty(state.resumeData, path));
    const { language } = useSelector((state) => state.resume)
    const noNullValue = value ? moment(value).utcOffset(8).format("YYYY.MM") : '';
    const placeholderVisible = !value;
    const text = placeholderVisible ? placeholder : (moment(value).get('year') === endYear && slot_value === 'end_date') ? '至今' : noNullValue;
    return createElement(tagName, {style: {...style, color: placeholderVisible ? 'gray' : undefined}, className}, language === 'en_US' ? convertEngDate(text) : text )
})