// 底部导航栏
import { ReactComponent as MyIcon } from '../svg/my.svg'
import { ReactComponent as MyLightIcon} from '../svg/my_light.svg'
import { ReactComponent as HomeIcon} from '../svg/home.svg'
import { ReactComponent as HomeLightIcon } from '../svg/home_light.svg'
import { ReactComponent as InterviewIcon} from '../svg/interview.svg'
import { ReactComponent as CVIcon} from '../svg/cv.svg'
import { ReactComponent as CVLightIcon} from '../svg/cv_light.svg'
import { ReactComponent as InterviewLightIcon} from '../svg/interview_light.svg'
import { listResumeUser } from "@/http/api";
import './index.scss'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect,useRef, useState } from 'react'
import { useSelector } from 'react-redux'


const router = [
  {
    title: '首页',
    icon: <HomeIcon />,
    lightIcon: <HomeLightIcon />,
    url: '/mobile/index',
  },

  {
    title: '改简历',
    icon: <CVIcon/>,
    lightIcon: <CVLightIcon />,
    url: '/mobile/resume'
  },
  {
    title: '练面试',
    icon: <InterviewIcon/>,
    lightIcon: <InterviewLightIcon />,
    url: '/mobile/interview'
  },

  {
    title: '我的',
    icon: <MyIcon />,
    lightIcon: <MyLightIcon />,
    url: '/mobile/my'
  }
]

// 登录，付款
const MobileLayout = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { children, style } = props
  const resumeUserList = useSelector(state => state.mobile.resumeUserList)
  const pageStatus = useSelector(state => state.mobile?.pageStatus) ?? {}
  const {id,jdId,isTemplate} = pageStatus
  const handleNavigation = (url) => {
    navigate(url, { replace: true })
  }
  return <div style={style} className="bottom-layout">
    <div className="layout-content">
      {children}
    </div>
    <div className="navigation">
      <div className="router-list">
        {
          router.map((item, index) => {
            let { title, icon, lightIcon, url } = item
            const pathname = location.pathname
            const isActive = pathname.includes(url)
            const cls = classNames({
              'router-item': true,
              'active': isActive,
            })
            let newUrl = url
            if (title === '改简历' && resumeUserList[0]){
              newUrl = newUrl + `?id=${resumeUserList[0].id}`
            }
            if (title === '练面试' && id){
              newUrl = newUrl + `/question?id=${id}&jdId=${jdId}&isTemplate=${isTemplate}`
            }
            return <div key={index} onClick={() => handleNavigation(newUrl)} className={cls}>
              <div className="icon">
                {isActive ? lightIcon : icon}
              </div>
              <div className="title">{title}</div>
            </div>
          })
        }
      </div>
    </div>
  </div>
}

export default MobileLayout
