import { Select, Input, Spin } from "antd";
import MobileLayout from "../../layout";
import "./index.scss";
import { useEffect, useRef, useState } from "react";
import {
  listResumeUser,
  getJobDescription,
  generateQuestions,
  updateJobDescription,
  addJobDescription,
  getResumeDataById,
  getInterviewQuestionList,
  getInterviewQuestionListTemplate,
} from "@/http/api";
import { useNavigate, useLocation } from "react-router-dom";
import "./index2.scss";
import { icon3, processDesc, rightSvg } from "./const";
import { useDispatch } from "@/components/resume/redux/hooks";
import withStore from "@/components/resume/hoc/withStore";
import interviewSlice from "@/components/resume/redux/services/interviewSlice";
import useSelector from "@/components/resume/redux/hooks/useSelector";
import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from "react-redux";
import mobileSlice, { setPageStatus } from "@/pages/Mobile/mobileSlice";
import { debounce } from "lodash";
import { processInterviewData } from "@/pages/ResumeDetail/component/Interview";
import { message } from "antd";
import { Dialog, Toast } from "antd-mobile";
import { ExclamationCircleFill } from "antd-mobile-icons";
import isPay, { isSVIP } from "@/common/isVip";
import PayModal from "../../components/Modal/PayModal";
import isLogin from "@/common/isLogin";
import errorSlice from "@/redux/services/errorSlice";
import store from "@/redux/store";
import { fetchAnonymousUserThunk, logoutUserThunk } from "@/redux/thunk/user";
import SvipTipModal from "./svipTipModal";
import classNames from "classnames";
import CommonModal from "./question/components/CommonModal";

const closeIcon = (
  <div
    style={{ marginTop: "3px" }}
    dangerouslySetInnerHTML={{
      __html: `<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="改简历、练面试、接offer-移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="练面试" transform="translate(-300.000000, -106.000000)" fill="#7C582C" fill-rule="nonzero">
            <g id="编组-5" transform="translate(289.000000, 101.000000)">
                <g id="超级简历" transform="translate(18.000000, 12.000000) rotate(-360.000000) translate(-18.000000, -12.000000) translate(11.000000, 5.000000)">
                    <g id="suo" transform="translate(2.333333, 0.777778)">
                        <path d="M4.79042568,1.31755746 C5.86554241,1.31755746 6.75152999,2.13119362 6.88607824,3.18473186 L8.15286278,3.18473186 C8.00308264,1.40133279 6.54336099,0 4.76250057,0 C2.98164015,0 1.52318781,1.40133279 1.37340768,3.18473186 L2.69604243,3.18473186 C2.82805204,2.13119362 3.71530894,1.31755746 4.79042568,1.31755746 Z M7.91169137,3.96028832 L1.42037264,3.96028832 C0.635930913,3.96028832 0,4.59621923 0,5.38066095 L0,9.95149372 C0,10.7359354 0.635930913,11.3718664 1.42037264,11.3718664 L7.9129607,11.3718664 C8.69867174,11.3718664 9.33333333,10.7359354 9.33333333,9.95149372 L9.33333333,5.38193028 C9.33333333,4.59748855 8.6961331,3.96028832 7.91169137,3.96028832 Z M5.27022984,8.82052677 C5.27022984,9.16959064 4.98717077,9.45138039 4.63937622,9.45138039 C4.29031234,9.45138039 4.0085226,9.16832132 4.0085226,8.82052677 L4.0085226,7.8037989 C3.83843329,7.64005621 3.73181015,7.41157804 3.73181015,7.15644408 C3.73181015,6.66013872 4.13545492,6.25649395 4.63176028,6.25649395 C5.12806564,6.25649395 5.53171041,6.66013872 5.53171041,7.15644408 C5.52917177,7.40269278 5.43016456,7.62609366 5.27022984,7.78856702 L5.27022984,8.82052677 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);
const svipContent = "为SVIP专属功能，请先";
const InterView = () => {
  const [optionList, setOptionList] = useState([]);
  const [selectId, setSelectId] = useState();
  const [inputValue, setInputValue] = useState("");
  const selectJdId = useSelector((state) => state.interview.selectJdId);
  const chooseName = useSelector((state) => state.interview.chooseName);

  const resumeUserList = useReduxSelector(
    (state) => state.mobile.resumeUserList
  );
  const pageStatus = useSelector((state) => state.interview.pageStatus);
  const dispatch = useDispatch();
  const reduxDispatch = useReduxDispatch();
  const [dataList, setDataList] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [payModalVisible, setPayModalVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [svipModalVisible, setSvipModalVisible] = useState(false);
  const state = useLocation();
  const chooseKey = state?.state?.chooseKey;
  const [jobTitle, setJobTitle] = useState("");

  const urlId = useRef("")
  const urlContent = useRef("");
  useEffect(() => {
    if (state?.state?.content) {
      urlContent.current =
        "职位名称:" +
        state?.state?.jobTitle +
        "\n \n \n" +
        state?.state?.content;
      setJobTitle(state?.state?.jobTitle);
       urlId.current =  state?.state?.id
    }
  }, [state?.state?.content]);
  // const lockBtnRef= useRef(false)

  const navigate = useNavigate();

  const trueSvip = isSVIP();
  // useEffect(() => {
  //   const { status, id, jdId } = pageStatus;
  //   if (status === 2) {
  //     navigate(`/mobile/interview/question?id=${id}&jdId=${jdId}`);
  //   }
  // }, [pageStatus]);

  const handlePaySuccess = () => {
    window.location.reload();
  };

  const onClickUpgrade = () => {
    if (!isLogin()) {
      store.dispatch(errorSlice.actions.setCode(1009));
      return;
    }
    setPayModalVisible(true);
  };

  useEffect(() => {
    if (resumeUserList && resumeUserList.length > 0) {
      setPageLoading(true);
      processResumeUserList(resumeUserList);
    }
  }, [resumeUserList]);

  const processResumeUserList = async (dataList) => {
    const temp = dataList.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setOptionList(temp);
    // 选择模板出来
    if (temp.length) {
      if (chooseKey) {
        setSelectId(chooseKey);
        getDataList(chooseKey);
      } else {
        getDataList(temp[0]?.value);
        setSelectId(temp[0]?.value);
      }
    }
    setPageLoading(false);
  };
  const GoButton = () => (
    <div
      className={classNames({ "filter-blur": !trueSvip })}
      onClick={clickMore}
      style={{
        position: "absolute",
        background: "#F7F8F9",
        borderRadius: "11px",
        zIndex: 10,
        right: "10px",
        display: "flex",
        top: "9px",
        height: "26px",
        textAlign: "center",
        width: "110px",
        borderRadius: "4px",
        justifyContent: "center",
        lineHeight: "26px",
      }}
    >
      <div
        style={{
          fontFamily: "PingFangSC, PingFang SC",
          fontWeight: 400,
          fontWize: "12px",
          color: "#969FB2",
          background: "#F7F8F9",
        }}
      >
        选择目标岗位
      </div>
      {rightSvg}
    </div>
  );
  const [jobInterest, setJobInterest] = useState("");

  const isTemplate = useRef(false);
  const isFromUrl = useRef(false);
  const getDataList = async (id) => {
    const resumeData = await getResumeDataById(id);
    setJobInterest(
      resumeData?.resumeData?.job_interest?.data?.JOB_INTEREST_ROLE
    );
    let times = 0;
    getJobDescription({
      query: {
        resumeId: id || resumeId,
      },
    })
      .then((data) => {
        if (data) {
          //  id  有 templateId 时 是模板创建
          const { templateId, id } = data;
          if (templateId) {
            isTemplate.current = true;
            updateId.current = "";
          } else {
            isTemplate.current = false;
            updateId.current = id;
          }
          // 接口有改动 兼容
          let newDataList = [{ ...data }].map((item) => {
            const { jobTitle } = item;
            setJobTitle(jobTitle);
            const { content } = processDesc(item.description);
            return {
              id: item.id ?? item.templateId,
              title: jobTitle,
              content,
              description: item.description,
            };
          });
          if (newDataList && newDataList.length > 0) {
            const jdId = newDataList[newDataList.length - 1].id;
            dispatch(interviewSlice.actions.setJdId(jdId));
            // 选择目标岗位跳转
            if (!urlContent.current) {
              setDataList(newDataList);
            } else if (urlContent.current && times === 0) {
              newDataList[0].description = urlContent.current;
              isFromUrl.current = true;
              setDataList(newDataList);
              times++;
            }
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const updateId = useRef("");
  const handleUpdateContent = (e) => {
    const { content } = processDesc(e.target.value);
    const newDataList = dataList.map((item) => {
      console.log(item)
      if (item.id === selectJdId) {
        return {
          ...item,
          title: jobTitle,
          content,
          description: e.target.value,
        };
      } else {
        return item;
      }
    });
    // 模板添加
    if (isTemplate.current) {
      addJobDescription({
        body: {
          content,
          description: e.target.value,
          resumeUserId: selectId,
          title: jobTitle,
        },
      })
        .then((res) => {
          updateId.current = res.id;
        })
        .catch((err) => {
          console.log(err);
        });

      isTemplate.current = false;
      isFromUrl.current = false;
    } else {
      updateJobDescription({
        body: {
          content,
          description: e.target.value,
          id: updateId.current,
          resumeUserId: selectId,
          title: jobTitle,
        },
      });
      isFromUrl.current = false;
    }

    setDataList(newDataList);
  };

  const handleClickButton = async () => {
    if (!isLogin()) {
      store.dispatch(errorSlice.actions.setCode(1009));
      return;
    }
    if (btnLoading) return;
    setBtnLoading(true);
    // 选择模板进来没这个 id
    let jdId = parseInt(dataList[0].id);
    const isTemplateData = !updateId.current || isFromUrl.current;
    try {
      //有id去生成 已更新的id
      if (isTemplateData) {
        const idTitle = "jobDescriptionTemplateId";
        if(isFromUrl.current){
          jdId = urlId.current
        }
        const queryQuestionList = await getInterviewQuestionListTemplate({
          query: { [idTitle]: jdId, resumeId: selectId },
        });

        if (queryQuestionList) {
          const newList = processInterviewData(queryQuestionList);
          dispatch(interviewSlice.actions.setList(newList));
        }
      } else {
        const res = await generateQuestions({
          query: {
            resumeId: selectId,
            userResumeJobDescriptionId: updateId.current,
          },
        });
        if (res) {
          const newList = processInterviewData(res);
          dispatch(interviewSlice.actions.setList(newList));
        }
      }
    } catch (error) {
      if (error) {
        console.log(error);
        if (!error.code == 10001) {
          Toast.show("生成失败");
        }
        return;
      }
    } finally {
      message.destroy();
      setBtnLoading(false);
    }

    dispatch(
      interviewSlice.actions.setPageStatus({
        status: 2,
        id: selectId,
        jdId: isTemplateData ? jdId : updateId.current,
        pageIndex: 0,
        isTemplate: isTemplateData,
      })
    );
    reduxDispatch(
      setPageStatus({
        pageStatus: {
          status: 2,
          id: selectId,
          jdId: isTemplateData ? jdId : updateId.current,
          pageIndex: 0,
          isTemplate: isTemplateData,
          times:0
        },
      })
    );
    navigate(
      `/mobile/interview/question?id=${selectId}&jdId=${
        isTemplateData ? jdId : updateId.current
      }&isTemplate=${isTemplateData}`
    );
  };

  const handleChangeSelect = (v) => {
    setSelectId(v);
    dispatch(interviewSlice.actions.setChooseName(v));
    getDataList(v);
  };
  const clickOption = () => {
    svipFeature.current = "选择简历";
    if (!isLogin()) {
      store.dispatch(errorSlice.actions.setCode(1009));
      return;
    }
    if (!trueSvip) {
      setCommonModalVisible(true);
      setCommonModalContent("选择简历" + svipContent + sureText);
    }
  };
  const clickText = () => {
    svipFeature.current = "修改目标岗位描述";
    if (!isLogin()) {
      store.dispatch(errorSlice.actions.setCode(1009));
      return;
    }
    if (!trueSvip) {
      setCommonModalVisible(true);
      setCommonModalContent("修改目标岗位描述" + svipContent + sureText);
    }
  };
  const clickMore = () => {
    svipFeature.current = "选择目标岗位";
    if (btnLoading) {
      return;
    }
    if (!isLogin()) {
      store.dispatch(errorSlice.actions.setCode(1009));
      return;
    }
    if (!trueSvip) {
      setCommonModalVisible(true);
      setCommonModalContent("选择目标岗位" + svipContent + sureText);
      return;
    }
    navigate("/mobile/interview/chooseJob", {
      state: {
        searchValue: jobInterest,
        chooseKey: selectId,
      },
    });
  };
  const [commonModalVisible, setCommonModalVisible] = useState(false);
  const [commonModalContent, setCommonModalContent] = useState("");
  const svipFeature = useRef("");
  const sureText = "升级";
  return (
    <MobileLayout>
      {resumeUserList.length > 0 ? (
        <div className="iv-page">
          <div className="iv-select">
            <div
              className={classNames({
                "iv-select-title": true,
                "filter-blur": !trueSvip,
              })}
            >
              选择简历
            </div>
            {optionList.length > 0 && (
              <Select
                disabled={!trueSvip || btnLoading}
                onChange={(v) => handleChangeSelect(v)}
                onClick={clickOption}
                options={optionList}
                value={selectId}
                // defaultValue={optionList?.[0]?.value}
                popupClassName="iv-ant-popup"
                className={classNames({
                  "iv-ant-select": true,
                  "filter-blur": !trueSvip,
                })}
              />
            )}
            {!trueSvip && (
              <div className="svip-content" onClick={onClickUpgrade}>
                {closeIcon}
                <div>SVIP</div>
              </div>
            )}
          </div>
          <div className="iv-description">
            <div className="iv-select-title">目标岗位描述</div>
            <GoButton />
            <Input.TextArea
              onClick={clickText}
              readOnly={!trueSvip}
              disabled={btnLoading}
              value={dataList?.[0]?.description || ""}
              onChange={handleUpdateContent}
              className="iv-ant-input"
            ></Input.TextArea>
            {!dataList?.[0]?.description && (
              <div className="tips">
                <p>
                  请输入岗位描述，或者
                  <span
                    onClick={clickMore}
                    style={{
                      zIndex: "100",
                      color: "#19A493",
                      textDecoration: "underline",
                    }}
                  >
                    选择目标岗位
                  </span>
                </p>
                <p>职位要求：</p>
                <p>
                  1、本科及以上学历，计算机软件相关专业，3年以上互联网开发经验
                </p>
                <p>2、熟练掌握Java技术，对多线程、数据结构、GC等有深刻认识</p>
              </div>
            )}
          </div>
          <div
            onClick={handleClickButton}
            className="iv-button"
            style={{ opacity: btnLoading ? 0.5 : 1 }}
          >
            {" "}
            {btnLoading ? (
              <>
                <Spin className="ant-span" spinning={true}></Spin>{" "}
                测试问题生成中...
              </>
            ) : (
              <>生成面试问题{icon3}</>
            )}
          </div>
        </div>
      ) : (
        <div className="empty-page">
          <div className="empty-modal">
            <div className="content">
              面试问题基于简历生成，暂无简历，请先新建简历
            </div>
            <div onClick={() => navigate(`/mobile/index`)} className="btn">
              新建简历
            </div>
          </div>
        </div>
      )}
      <SvipTipModal
        visible={svipModalVisible}
        onCancel={() => setSvipModalVisible(false)}
        onClickUpgrade={onClickUpgrade}
      />
      <PayModal
        defaultVipType="svip"
        visible={payModalVisible}
        onClose={() => setPayModalVisible(false)}
        onSuccess={handlePaySuccess}
      />
      <CommonModal
        sureText={sureText}
        visible={commonModalVisible}
        onConfirm={() => {
          setCommonModalVisible(false);
          setPayModalVisible(true);
        }}
        onCancel={() => setCommonModalVisible(false)}
        content={commonModalContent}
      />
    </MobileLayout>
  );
};

export default InterView;
