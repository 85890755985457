import styled from "styled-components"
import {createPortal} from "react-dom";
import { useState } from "react";
import http from "@/http/pptHttp";
import { userBehaviorLog } from "@/http/api";

const NavWxButtonStyled = styled.div`
    width: 100vw;
    height: 106px;

    position: fixed;
    background: linear-gradient( 180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 63%, #FFFFFF 100%);
    bottom: 78px;

    .btn{
        width: 331px;
        height: 44px;
        background: #06C260;
        border-radius: 22px;
        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        margin-top: 17px;
        .img{
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }
`


const NavWxButton = () => {

    const [loadingVisible, setLoadingVisible] = useState(false)

    const handleClick = () => {
        setLoadingVisible(true)
        userBehaviorLog({ query: { type: 'GO_TO_WX_MINI'}})
        window.location.href = 'weixin://dl/business/?appid=wxe7538096ffeb308b&path=pages/webview/index'
        setTimeout(() => {
            setLoadingVisible(false)
        }, 1500)
    }

    return (
        <NavWxButtonStyled>
            <div className="btn" onClick={handleClick}>
                <img className="img" src={'https://oss.jianzeppt.com/jz-ppt-img/wechat_icon.png'} />点击前往小程序使用
            </div>


            {
                loadingVisible && createPortal(<div style={{
                    background: 'rgba(0,0,0,0.7)',
                    borderRadius: 8,
                    width: 249,
                    height: 88,
                    position: 'fixed',
                    transform: 'translate(-50%, -50%)',
                    left: '50%',
                    top: '50%',
                    color: '#FFFFFF',
                }}>
                    <div style={{
                        width: 30,
                        height: 30,
                        background: '#20C400',
                        borderRadius: '50%',
                        margin: '14px auto 0'
                    }}><img style={{
                        width: 24,
                        height: 24,
                        marginTop: 3,
                        marginLeft: 3,
                    }} src='https://oss.jianzeppt.com/jz-ppt-img/wechat_icon.png' /></div>
                    <p style={{textAlign: 'center'}}>即将打开微信小程序...</p>
                </div>, document.body)
            }
        </NavWxButtonStyled>
    )
}

export default NavWxButton