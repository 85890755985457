import JzImage from "@/components/JzImage"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import UserAnswer from "./UserAnswer"
import SystemAnswer from "./SystemAnswer"
import { getInterviewQuestionList, getInterviewReportPdf,getInterviewQuestionListTemplate } from "@/http/api"
import useQuery from "@/hooks/useQuery"
import useQueryParam from "@/hooks/useQueryParam"
import useSelector from "@/components/resume/redux/hooks/useSelector"
import useDispatch from "@/components/resume/redux/hooks/useDispatch"
import interviewSlice from "@/components/resume/redux/services/interviewSlice"
import { message } from "antd"
import SaveModal from "./components/SaveModal"
import { useNavigate } from "react-router-dom"
import JzThemeButton from "@/components/JzThemeButton"
import isPay,{ isSVIP } from "@/common/isVip";
import InfoModal from "../Interview/components/InfoModal";
import PayModal from "@/components/PayModal";

function openCenteredWindow(url, title, w, h) {
    // 窗口的宽度和高度（以像素为单位）
    const width = w || 600;
    const height = h || 400;
  
    // 计算窗口打开的位置
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
  
    // 打开新窗口
    const newWindow = window.open(url, title, `
      toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      copyhistory=no,
      width=${width},
      height=${height},
      top=${top},
      left=${left}
    `);
  
    // 如果浏览器允许，将新窗口置于焦点
    if (newWindow) {
      newWindow.focus();
    }
}

const InterviewStyled = styled.div`
    flex: 1;
    margin-right: 12px;
    ${props => props.print ? `
    width: 720px; margin: 0 auto;-webkit-print-color-adjust: exact;
    ` : `height: calc(100vh - 88px);overflow-y: scroll;`}
    
    
`
const InterviewWrapStyled = styled.div`
    position: relative;
    padding: 44px 23px;
    padding-top: 56px;
    background-color: #fff;
    border-radius: 4px;
    .header-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 820px;
        height: 121px;
    }
    .header {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        color: #333;
        height: 32px;
        margin-bottom: 26px;
        .download-btn {
            font-size: 14px;
            background: linear-gradient(270deg, #37EDBA 0%, #55BBEC 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            padding: 6px 13px;
            box-sizing: border-box;
            border-radius: 4px;
            margin-left: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
        }
        .download-btn:hover {
            cursor: pointer;
            opacity: 0.8;
        }
        
        .re-generation{
            display: flex;
            align-items: center;
            margin-left: auto;
            color: #777777;
            font-size: 14px;
            cursor: pointer;
        }
    }
`

const PrimaryBtnStyled = styled.div`
    font-size: 14px;
    background: linear-gradient(270deg, #37EDBA 0%, #55BBEC 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 6px 13px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`

const QuestionListStyled = styled.div`
    position: relative;

    @media print {
        
    }
`

const QuestionListItemStyled = styled.div`
    background-color: #fff;
    border-radius: 8px;
    outline: 1px solid #E6E6E6;
    margin-bottom: 16px;
    padding: 24px 20px 16px 20px;
    page-break-inside: avoid;
    .question {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #343542;
        margin-top: 14px;
        margin-bottom: 14px;
    }
    .question:first-child {
        margin-top: 0;
    }
    .strategy {
        background: #F9FAFA;
        border-radius: 8px;
        padding: 14px 12px 10px 12px;
        .tip {
            font-weight: 500;
            color: #69A099;
            margin-bottom: 4px;
        }
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
    }
    ${props => props.$active && `
        box-shadow: 0px 2px 8px 0px #C6D4D2;
        outline: none;
    `}
    &:hover {
        box-shadow: 0px 2px 8px 0px #C6D4D2;
        outline: none;
    }
`

const LoadingStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 300px;
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    border-radius: 4px;
    > img {
        width: 270px;
        height: 140px;
        margin-bottom: 12px;
    }
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 116px;
        background: linear-gradient(to bottom, rgba(63, 205, 188, 0.09), rgba(255, 255, 255, 0));
    }
`

const downloadSvg = <div style={{ marginRight: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }} dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="首次进入-进入答题状态" transform="translate(-301.000000, -199.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-7" transform="translate(289.000000, 192.000000)">
                <g id="文本备份-22" transform="translate(12.000000, 7.000000)">
                    <path d="M2.25,14.325 L2.25,15.825 L15.75,15.825 L15.75,14.325 L2.25,14.325 Z M4.0875,7.34999999 L7.3875,7.34999999 L7.3875,2.175 L10.425,2.175 L10.425,7.34999999 L13.725,7.34999999 L8.92500001,12.9 L4.0875,7.34999999 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon6 = <div style={{ marginRight: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }} dangerouslySetInnerHTML={{
    __html: `
<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="面试问题页面流程优化" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="生成面试问题页面" transform="translate(-1026.000000, -131.000000)" fill="#777777" fill-rule="nonzero">
            <g id="编组" transform="translate(310.000000, 66.000000)">
                <g id="编组-2" transform="translate(716.000000, 65.000000)">
                    <g id="zhongxinshengcheng" transform="translate(2.250000, 2.250000)">
                        <path d="M13.4796348,5.30360596 L13.4796348,1.92884847 L12.3789596,3.02952368 C11.1306009,1.13831311 9.01607065,0.000266350781 6.74999986,0 C3.02225049,0 0,3.02128073 0,6.74999986 C0,10.478719 3.02225049,13.5 6.74999986,13.5 C9.49813879,13.5007798 11.9724359,11.835524 13.006393,9.28931092 C13.1187552,8.99529116 12.9754087,8.66539308 12.6838041,8.54690399 C12.3921995,8.4284149 12.0593746,8.56482792 11.9348105,8.85388963 C11.0775795,10.9636522 9.02726644,12.3434383 6.74999986,12.3430786 C3.66132095,12.3430786 1.15692117,9.83916366 1.15692117,6.74999986 C1.15692117,3.66132095 3.66132095,1.15692117 6.74999986,1.15692117 C8.71197859,1.15674563 10.5304654,2.18505008 11.5415736,3.86642474 L10.1048773,5.30360596 L13.4796348,5.30360596 L13.4796348,5.30360596 Z" id="路径"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

// 处理list，处理各个card的status状态
export const processInterviewData = (data, questionList = []) => {
    const list =  data.map(item => {
        const { question, answer, reference } = item
        const currStatus = questionList.find(i => i.id === question.id)
        return {
            id: question?.id,
            question: question?.question,
            strategy: question?.questionReference,
            answer: answer?.answer,
            answerId: answer?.id,
            feedback: answer?.feedback,
            systemAnswer: reference?.reference,
            systemAnswerId: reference?.id,
            status: currStatus?.status || 'init',
            systemStatus: 'init',
        }
    })

    return updateInterviewStatus(list)
}

const updateInterviewStatus = (list) => {
    return list.map(item => {
        const newItem = { ...item }
        if (item.answer) {
            newItem.status = 'feedback'
        }
        if (item.systemAnswer) {
            newItem.systemStatus = 'feedback'
        }
        return newItem
    })
}

function downloadPdf(pdfUrl, fileName) {
    // 使用fetch API来请求PDF文件
    fetch(pdfUrl)
      .then(response => {
        if (response.ok) return response.blob();
        throw new Error('Network response was not ok.');
      })
      .then(blob => {
        // 创建一个Blob URL
        const blobUrl = window.URL.createObjectURL(blob);
  
        // 创建一个a标签
        const link = document.createElement('a');
        link.style.display = 'none';
  
        // 设置a标签的href属性为Blob URL
        link.href = blobUrl;
  
        // 设置下载文件的文件名
        link.download = fileName;
  
        // 将a标签添加到文档中
        document.body.appendChild(link);
  
        // 触发a标签的点击事件以开始下载
        link.click();
  
        // 移除a标签并释放Blob URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }
  

const Interview = ({ print = false,selectId, reloadInterview, onReGeneration = () => {},isTemplate=false}) => {
    const ref = useRef(null)
    const [loading, setLoading] = useState(false)
    const [isGenerate, setIsGenerate] = useState(false)
    const [selectIndex, setSelectIndex] = useState(0)
    const questionList = useSelector(state => state.interview.list)
    const selectJdId = useSelector(state => state.interview.selectJdId)
    const isContentModified = useSelector(state => state.interview.isContentModified)
    const dispatch = useDispatch()
    const resumeId = useQueryParam('id')
    const queryJdId = useQueryParam('jdId')
    const [saveVisible, setSaveVisible] = useState(false)
    const preClickRef = useRef(null)
    const navigate = useNavigate()
    const [svipModalVisible, setSvipModalVisible] = useState(false);
    const [payModalVisible, setPayModalVisible] = useState(false);

    useEffect(() => {
        if (print) {
            setSelectIndex(-1)
        }
    }, [selectIndex, questionList])

    useEffect(() => {
        if (questionList && questionList.length > 0) {
            setIsGenerate(false)
        } else {
            setIsGenerate(true)
        }
    }, [questionList])

    useEffect(() => {
        fetchQuestionList(resumeId, selectJdId)
    }, [resumeId, reloadInterview, selectJdId])

    const fetchQuestionList = (resumeId, selectJdId) => {
        // setLoading(true)
        return new Promise((resolve, reject) => {
            // 当selectJd不存在时，从query参数里拿
            const idTitle = isTemplate ? 'jobDescriptionTemplateId' : 'userResumeJobDescriptionId'
            const func = isTemplate ? getInterviewQuestionListTemplate : getInterviewQuestionList
            func({ query: { resumeId:selectId||resumeId, [idTitle]: selectJdId || queryJdId } }).then((data) => {
                if (data && data.length > 0) {
                    const newList = processInterviewData(data, questionList)
                    dispatch(interviewSlice.actions.setList(newList))
                    resolve(newList)
                } else {
                    resolve([])
                }
            }).catch((err) => {
                reject(err)
            }).finally(() => {
                // setLoading(false)
            })
        })
    }

    const handleChangeStatus = async (value, id, type, data) => {
        let list = questionList
        if (value === 'feedback') {
            // 需要重新更新一下接口
        }
        console.log('handleChangeStatus', value, id, type, data)
        const newList = list.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    answer: data ?? item.answer,
                    [type]: value
                }
            }
            return item
        })
        dispatch(interviewSlice.actions.setList(newList))
    }

    const handleSelectIndex = (e, index) => {
        if (index !== selectIndex) {

            if (isContentModified) {
                setSaveVisible(true)
                preClickRef.current = index
            } else {
                updateSelectStatus(index)
            }
        }
    }

    const handleSaveConfirm = () => {
        setSaveVisible(false)
        updateSelectStatus(preClickRef.current)
        dispatch(interviewSlice.actions.setIsContentModified(false))
    }

    const updateSelectStatus = (index) => {
        setSelectIndex(index)
        // 选中后，要重新更新list的状态
        const newList = updateInterviewStatus(questionList)
        dispatch(interviewSlice.actions.setList(newList))
    }

    const handleConfirmModal = () => {
        setSvipModalVisible(false);
        setPayModalVisible(true);
      };

    const handleDownload = () => {
        // console.log('ref', ref.current)
        // const url = new URL(window.location.href)
        // url.pathname = `/interviewReport`
        // // window.open(url) 
        // openCenteredWindow(url, "答题报告", 800, 600)
        if(!isSVIP()){
            setSvipModalVisible(true)
            return
        }
        message.loading({
            content: '下载中...',
            duration: 0
        })
        getInterviewReportPdf({
            query: {
                resumeId,
                userResumeJobDescriptionId: selectJdId
            }
        }).then(pdfLink => {
            downloadPdf(pdfLink, '答题报告')
        }).finally(() => {
            message.destroy()
        })
    }

    const handleGenerate = async () => {
        try {
            
            await window.generateInterviewByJd()
        } catch (error) {
            
        } finally {
            setIsGenerate(false)
        }
    }

    const reGeneration = () => {
        onReGeneration()
    }

    return <InterviewStyled print={print}>
        {
            isGenerate ?
                <LoadingStyled>
                    <div className="mask" />
                    <JzImage src="loadingaaa.png" />
                    {/* <div>正在根据您提供的职位描述为您生成 5 道面试题...</div> */}
                    <PrimaryBtnStyled onClick={handleGenerate}>基于目标岗位生成面试问题</PrimaryBtnStyled>
                </LoadingStyled> 
                :
                <InterviewWrapStyled id="InterviewWrapStyled" ref={ref}>
                    <JzImage className="header-img" src="mxncbvjdiuhf.png" draggable="false" />

                    {
                        <div className="header">
                            <span>📝面试问题(5)</span>
                            {/* {
                                !print && questionList.length > 0 && <div onClick={handleDownload} className="download-btn">
                                    {downloadSvg}
                                    下载答题报告
                                </div>
                            } */}
                            {
                                !print && questionList.length > 0 &&  <JzThemeButton.Type2 
                                styles={{ 
                                    width: 'auto', 
                                    height: 'auto', 
                                    padding:'6px 13px',
                                    marginLeft: 20}}
                                onClick={handleDownload}>
                                    {downloadSvg}
                                    下载答题报告
                                </JzThemeButton.Type2>
                            }
                            {
                                !print && <div onClick={reGeneration} className="re-generation">
                                    {icon6}
                                    重新生成
                                </div>
                            }
                        </div>
                    }
                    <QuestionListStyled className="q-list">
                        {questionList.map((item, index) => {
                            const isActive = index === selectIndex
                            return <QuestionListItemStyled
                                $active={isActive}
                                key={index}
                                onClick={(e) => handleSelectIndex(e, index)}
                                className="q-item"
                            >
                                <div className="question">Q{index + 1}：“{item.question}”</div>
                                <div className="strategy">
                                    <p className="tip">解题思路：</p>
                                    <p>{item.strategy}</p>
                                </div>
                                <UserAnswer 
                                    questionId={item.id}
                                    answerId={item.answerId}
                                    feedback={item.feedback}
                                    answer={item.answer} 
                                    isActive={isActive} 
                                    status={item.status} 
                                    onChange={(value, data) => handleChangeStatus(value, item.id, 'status', data)} 
                                />
                                <SystemAnswer 
                                    questionId={item.id}
                                    referenceId={item.systemAnswerId}
                                    isActive={isActive} 
                                    answer={item.systemAnswer}
                                    status={item.systemStatus} 
                                    onChange={(value,data) => handleChangeStatus(value, item.id, 'systemStatus',data)} 
                                />
                            </QuestionListItemStyled>
                        })}
                    </QuestionListStyled>

                    <SaveModal 
                        visible={saveVisible}
                        onCancel={() => setSaveVisible(false)}
                        onConfirm={() => handleSaveConfirm()}
                    />
                    <InfoModal
                        visible={svipModalVisible}
                        onConfirm={handleConfirmModal}
                        onCancel={() => setSvipModalVisible(false)}
                        title={'下载答题报告为SVIP专属功能，请先升级!'}
                    />
                    <PayModal
                        id={9}
                        defaultVipType="svip"
                        visible={payModalVisible}
                        onSuccess={() => setPayModalVisible(false)}
                        onCancel={() => setPayModalVisible(false)}
                    />
                </InterviewWrapStyled>
        }
    </InterviewStyled>
}

export default Interview