import configHandler from "@/common/config/configHandler";
import parseResumeData from "@/common/resume/parseResumeData";
import initState from "@/components/resume/redux/initState";
import loadFont, { fontFamilyList } from "@/utils/loadFont";
import { Module } from "../module/module";

export default (res, templateBodyStr) => {
    let template;
    let resume;
    let resumeData;
    const pagination = JSON.parse(JSON.stringify(initState.pagination));
    const body = JSON.parse(templateBodyStr);
    const data = res.pagesData?.[0];
    const info = res.info ? JSON.parse(res.info) : {};
    try {
        {
            resume = {}
            resume.id = data.id;
            resume.name = res.name;
            resume.originTemplateId = body?.originTemplateId
            resume.resumeId = data.resumeId;
            resume.language = JSON.parse(data?.userData)?.data_language
            resumeData = JSON.parse(data?.userData);
            parseResumeData(resumeData)
            resume.pageId = data.id;
        } //resume

        {
            template = configHandler(body.html)
            template.linkConfig = body.cssList.map((name) => {
                return {
                    href: name, rel: "stylesheet"
                }
            });
            template.id = res.templateId;
            template.originId = res.originId
        }

        // 添加 额外的模块，在模版中添加
        if (template.mainModuleConfig.length > 0 && !template.mainModuleConfig.includes(Module.APPLICATION_INFO)) {
            template.mainModuleConfig.push(Module.APPLICATION_INFO)
        }
        if (template.leftModuleConfig.length > 0 && !template.leftModuleConfig.includes(Module.APPLICATION_INFO)) {
            template.leftModuleConfig.push(Module.APPLICATION_INFO)
        }
        // 添加 额外的模块，在数据中添加
        if (!resumeData[Module.APPLICATION_INFO]) {
            resumeData[Module.APPLICATION_INFO] = {
                data: {
                    title_text: "报考信息",
                    school: '北京大学',
                    major: '产业经济学',
                    table: [{ subject: '初试成绩', score: 450 }, { subject: '英语', score: 75 }, { subject: '思想政治', score: 75 }, { subject: '业务课1', score: 100 }, { subject: '业务课2', score: 100 }, { subject: '业务课3', score: 100 }],
                    details: "这里是自定义描述。",
                },
                visible: false,
                id: "APPLICATION_INFO",
                type: "APPLICATION_INFO"
            }
        }


        {
            if (info.openAutoOnePage !== undefined) {
                pagination.openAutoOnePage = info.openAutoOnePage;
            }
            if (info.openAutoPagination !== undefined) {
                pagination.openAutoPagination = info.openAutoPagination;
            }
            if (info.onePageLevel !== undefined) {
                pagination.onePageLevel = info.onePageLevel;
            }
            if (info.defaultThemeIndex !== undefined) {
                resume.defaultThemeIndex = info.defaultThemeIndex;
            }
            if (info.themeIndex !== undefined) {
                resume.themeIndex = info.themeIndex;
            }
            if (info.fontSize !== undefined) {
                resume.fontSize = info.fontSize;
            }
            if (info.pageMargin !== undefined) {
                resume.pageMargin = info.pageMargin;
            }
            if (info.moduleMargin !== undefined) {
                resume.moduleMargin = info.moduleMargin;
            }
            if (info.fontFamily !== undefined) {
                resume.fontFamily = info.fontFamily;
                const font = fontFamilyList.find(i => i.fontFamily === info.fontFamily)
                if (font) {
                    loadFont(font)
                }
            }


        }//other
    } catch (error) {
        console.log(error, 'error');
    }


    return {
        template, resume, resumeData, theme: body.theme, pagination
    }
}
