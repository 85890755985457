import React, { useEffect, useRef, useState } from 'react';

function LazyLoadComponent({ children }) {
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []); 

  return <div ref={ref}>{isIntersecting && children}</div>;
}

export default LazyLoadComponent;